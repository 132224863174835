<template>
  <div class="opage">
    <HomePageTop />
    <Dialog ref="dialog" class="wenTiChuLiTanChuang" :config="config">
      <textarea v-if="diaCon == 'chuliyijian'" class="chuliyijian" :placeholder="$fanyi('请输入处理意见')"
        v-model="dealTxt"></textarea>
    </Dialog>
    <Dialog ref="dialog2" :config="config2">
      <div>
        <div class="lookPicBox">
          <img src="../../../../../assets/user-img/left.svg" alt="" @click="imgChe('-')" />

          <el-image class="bigImg" v-if="lookImgs.length != 0" :src="lookImgs[lookNum].image"
            :preview-src-list="[lookImgs[lookNum].image]">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
          <img src="../../../../../assets/user-img/right@2x.svg" alt="" @click="imgChe('+')" />
        </div>
        <p class="imgSubscript">{{ lookImgsAct }}/{{ lookImgs.length }}</p>
      </div>
    </Dialog>
    <div class="main">
      <div class="title">
        <h1>{{ $fanyi('问题商品明细') }}：</h1>
      </div>
      <div class="sn">
        <span class="snBox">{{ $fanyi('订单号') }}：{{ $route.query.order_sn }}</span>
        <div>
          <button class="tongYiHuiFu" v-show="form.status && ['待处理'].indexOf(form.status) != -1"
            @click="client_aswer('all')">
            {{ $fanyi('统一回复') }}
          </button>
          <button class="lianxiren" @click="tocaht">
            <img :src="require('../../../../../assets/img/contact.svg')" alt="" />
            {{ $fanyi('联系负责人') }}
          </button>
        </div>
      </div>
      <el-table :data="tableData" class="wentitableDataBox" style="width: 100%" :header-cell-style="{
        'text-align': 'center',
        background: '#F0F0F0',
        color: 'black',
        'font-size': '14px',
        'font-weight': '500',
      }" :cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="59" class="checkedbox">
        </el-table-column>
        <el-table-column type="index" width="60" label="No"> </el-table-column>
        <el-table-column :label="$fanyi('照片')" width="80">
          <template slot-scope="scope">
            <div>
              <el-popover placement="right" trigger="hover">
                <img :src="scope.row.order_detail.pic" alt="" style="width: 300px; height: 300px" />

                <el-image slot="reference" class="smallImg" @click="
                  $fun.toCommodityDetails({
                    goods_id: scope.row.order_detail.goods_id,
                    shop_type: scope.row.order_detail.shop_type,
                  })
                  " :src="scope.row.order_detail.pic">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$fanyi('属性')" width="120">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="hover">
              <div class="goodsDetailAll">
                <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in scope.row.order_detail
                  .detail" :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                  <h1 class="detailTitle">{{ detailItem.key }}</h1>
                  <p>
                    <span class="detailBody">{{ detailItem.value }}</span>
                  </p>
                </div>
              </div>

              <div class="detailGoodsBox" slot="reference">
                <p class="detailGoods" v-for="(item, index) in scope.row.order_detail.detail" :key="index"
                  :title="item.key + ':' + item.value">
                  {{ item.key }}:{{ item.value }}
                </p>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column property="order_detail.num" :label="$fanyi('数量')" width="80"></el-table-column>
        <el-table-column property="order_detail.price" :label="$fanyi('单价') + ' (￥)'" width="100">
        </el-table-column>
        <el-table-column property="order_detail.subtotal" :label="$fanyi('产品费用') + ' (￥)'" width="80"></el-table-column>
        <el-table-column property="order_detail.confirm_freight" :label="$fanyi('中国运费') + '(￥)'"
          width="86"></el-table-column>
        <el-table-column property="name" :label="$fanyi('附加服务')" width="120">
          <template slot-scope="scope">
            <el-popover placement="bottom" width="630" trigger="hover">
              <el-table :data="scope.row.order_detail.option" :header-cell-style="{
                'text-align': 'center',
              }" :cell-style="{ 'text-align': 'center' }" size="mini">
                <el-table-column property="tag" label=""></el-table-column>
                <el-table-column property="name" :label="$fanyi('名称')">
                  <template slot-scope="scope">
                    <div style="
                            
                                          " :title="scope.row.name_translate">
                      {{ scope.row.name_translate }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column property="num" :label="$fanyi('数量')"></el-table-column>
                <el-table-column property="price" :label="$fanyi('单价')" width="130px">
                  <template slot-scope="scope">
                    <div>
                      {{ $fun.RMBNumSegmentation(scope.row.price) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column property="price" :label="$fanyi('总价')">
                  <template slot-scope="scope">
                    <div>
                      {{
                        $fun.RMBNumSegmentation(
                          $fun.roundNumber(scope.row.price * scope.row.num, 2)
                        )
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <div class="fbaBox">
                      <el-popover placement="bottom" width="400" trigger="hover">
                        {{ scope.row.fba }}
                        <span slot="reference">{{ scope.row.fba }}</span>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <div class="showOption" slot="reference">
                <div class="showOptionCon">{{ $fanyi('明细') }} ></div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column :label="$fanyi('备注栏')" width="140">
          <template slot-scope="scope">
            <div>
              <div class="ramark client_deal">
                {{ scope.row.order_detail.client_remark }}
              </div>
            </div>
          </template></el-table-column>
        <el-table-column :label="$fanyi('问题照片')" width="80">
          <template slot-scope="scope">
            <el-image v-if="scope.row.problem_goods_image.length > 0" class="lookPic"
              @click.native="lookPicBoxOpen(scope.row.problem_goods_image)" :src="scope.row.problem_goods_image[0].image">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <span v-else>——</span>
          </template>
        </el-table-column>
        <el-table-column property="num" :label="$fanyi('有问题的商品数')" width="80"></el-table-column>
        <el-table-column property="description_translate" :label="$fanyi('问题说明')" width="140">
          <template slot-scope="scope">
            <div>
              <div class="ramark client_deal">
                {{ scope.row.description_translate }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column property="client_deal" :label="$fanyi('处理意见')" min-width="140">
          <template slot-scope="scope">
            <div>
              <textarea class="ramark client_deal hui" @click="
                                                                                                                                                                                                                                                                                                          ;['待处理'].indexOf(form.status) != -1
                ? client_aswer('one', scope.row)
                : ''
                " style="cursor: pointer" v-model="scope.row.client_deal" :placeholder="$fanyi('请输入处理意见')" readonly>
                                  </textarea>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Dialog from '../../../../../components/public/Dialog'

import HomePageTop from '../../../../../components/head/HomePageTop.vue'
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      form: {
        order_sn: this.$route.query.order_sn,
        status: this.$fun.fromCode(this.$route.query.status),
      },
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('处理意见'),
        btnTxt: [this.$fanyi('确认'), this.$fanyi('取消')],
      },
      config2: {
        top: '5.5vh',
        width: '1200px',
        title: this.$fanyi('问题图片'),
        btnTxt: [],
      },
      lookImgs: [],
      lookImgsAct: 1,
      lookNum: 0,
      bigImgs: [],
      dealTxt: '',
      diaCon: 'chuliyijian',
    }
  },
  components: {
    HomePageTop,
    Dialog,
  },
  computed: {},
  created() {
    this.getData()
  },
  methods: {
    // 打开聊天窗口
    tocaht() {
      let element = document.getElementById("zsiq_float");
      // 创建事件
      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);
      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi('是否前往登录?'),
      //     () => {
      //       this.$store.commit('getactivePage', this.$route.fullPath)
      //       this.$fun.toPage('/login')

      //     },
      //     () => { }
      //   )
      // }
    },
    getData() {
      let datas = {
        order_sn: this.form.order_sn,
        status: this.form.status,
      }
      this.$api.problemGoodsOrderDetail(datas).then((res) => {

        if (res.code != 0) return
        this.tableData = res.data
        this.dataProcessing()
      })
    },
    // 数据处理
    dataProcessing() {
      this.tableData.forEach((item) => {
        item.order_detail.detail = JSON.parse(item.order_detail.detail)
        item.order_detail.option = JSON.parse(item.order_detail.option)
      })
    },
    // 打开查看图片弹窗
    lookPicBoxOpen(imgs) {
      this.lookImgs = imgs
      this.lookNum = 0
      this.$refs.dialog2.open('')
    },
    // 图片弹窗切换图片
    imgChe(type) {
      if (type == '+') {
        if (this.lookNum < this.lookImgs.length - 1) {
          this.lookNum++
          this.lookImgsAct++
        } else {
          this.lookNum = 0
          this.lookImgsAct = 1
        }
      }
      if (type == '-') {
        if (this.lookNum > 0) {
          this.lookNum--
          this.lookImgsAct--
        } else {
          this.lookNum = this.lookImgs.length - 1
          this.lookImgsAct = this.lookImgs.length
        }
      }
      this.$forceUpdate()
    },
    // 打开客户回复窗口
    client_aswer(type, row) {
      this.diaCon = 'chuliyijian'

      this.$refs.dialog.open(
        '',
        () => {
          let ids = []
          if (type == 'all') {
            this.multipleSelection.forEach((item) => {
              ids.push(item.id)
            })
          } else {
            ids = [row.id]
          }
          ids = ids.join(',')

          this.$api
            .problemGoodsClientDeal({
              ids: ids,
              deal: this.dealTxt,
            })
            .then((res) => {

              if (res.code != 0) return
              this.$store.dispatch('sidebarNumList')
              this.dealTxt = ''
              this.getData()
            })
        },
        () => { }
      )
    },
    handleSelectionChange(val) {

      this.multipleSelection = val
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../../../../css/mixin.scss';

.opage {
  background-color: #f9f9f9;
  min-height: 100vh;
}

.main {
  width: 1400px;
  margin: 20px auto 0;
  background: #ffffff;
  padding: 30px 20px;
  border: 1px solid #e2e2e2;
  border-radius: 6px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    >h1 {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 1px;
      font-weight: 500;
      line-height: 28px;
    }

    .el-button {
      border-radius: 4px;
      padding: 6px 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .sn {
    margin-bottom: 15px;

    &,
    >* {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    button {
      transform: 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }

    .tongYiHuiFu {
      color: white;
      height: 40px;
      line-height: 20px;
      font-weight: 500;
      background: #ff730b;
      border-radius: 4px;
      border: 1px solid #ff730b;
      padding: 0 15px;
    }

    .lianxiren {
      height: 40px;
      border-radius: 4px;
      border: 1px solid #ff730b;
      padding: 0 15px;
      color: #ff730b;
      font-size: 14px;
      font-weight: 500;
      margin-left: 20px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }
}

.wentitableDataBox,
/deep/.wentitableDataBox {
  border: solid #e2e2e2 1px;
  border-bottom: none;

  thead {
    .el-table__cell {
      background: #f6f6f6 !important;
      padding: 10px 0;
      border-bottom: 1px solid #e2e2e2;

      .cell {
        font-size: 13px;
        padding-top: 2px;
        font-weight: 500;
        line-height: 20px;
        word-break: normal;
      }
    }
  }

  tbody {
    .el-table__row {
      border-bottom: 1px solid #e2e2e2;
    }
  }
}

/deep/.el-checkbox {
  transform: scale(1.25);
}

/deep/.el-checkbox__input {
  height: 20px;
  border-radius: 50%;
}

/deep/.el-checkbox__inner {
  border-radius: 50%;
}

.detailGoodsBox {
  overflow-y: auto;
  height: 66px;

  .detailGoods {
    color: #222;
    text-align: left;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

p {
  font-size: 12px;

  color: #888888;
  line-height: 20px;
}

/deep/.el-image.lookPic {
  cursor: pointer;
  $imgSize: 50px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}

.ramarkTime {
  min-height: 20px;
}

.ramark {
  position: relative;
  width: 120px;
  height: 80px;
  overflow-y: auto;
  border-radius: 4px;
  text-align: left;
  display: flex;
  margin: 0px auto;
  background: #fafafa;
  border: 1px solid #e2e2e2;
  padding: 6px 10px;
  line-height: 18px;
  font-size: 12px;

  &::placeholder {
    color: #cdcdcd;
  }

  &.client_deal {
    border: 1px solid #e2e2e2;
    background: #fafafa;
  }

  // 待处理状态下回复框
  &.hui {
    border: 1px solid #e2e2e2;

    background: #fafafa;
    cursor: default !important;
  }
}

/deep/.el-dialog__body {
  padding-bottom: 20px !important;
}

.chuliyijian {
  width: 420px;
  height: 160px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgba(96, 139, 255, 0.3);
  padding: 15px 20px;
  font-size: 14px;
  line-height: 19px;
  margin: 0 auto;
  display: block;
}

.lookPicBox {
  width: 1120px;
  height: 640px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

/deep/.el-image.bigImg {
  $imgSize: 600px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}

/deep/.el-image.smallImg {
  $imgSize: 60px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

/deep/input[type='checkbox'] {
  height: 0px;
}

.showOptionCon {
  min-width: 100px;
  height: 30px;
  background: #eff4ff;
  border-radius: 4px;
  border: 1px solid #608bff;
  font-size: 12px;
  line-height: 18px;
  line-height: 30px;
  color: #608bff;
}

.fbaBox {
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.imgSubscript {
  text-align: center;
  font-size: 20px;
  color: #000000;
}
</style>
<style lang="scss">
.wenTiChuLiTanChuang {
  .el-dialog__footer {
    .el-button--default {
      &:last-child {
        border: 1px solid #e2e2e2 !important;
      }
    }
  }
}
</style>
